import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "items", "pointDeductions", "points", "votedCount"]
    // static values = {
    //   id: String
    // }

    connect() {
     this.updatePoints()
     let that = this
    }

    updatePoints(){
      var negative_score = 0;
      var score = 0;
      var voted_count = 0;
     
      for (var key in this.itemsTargets) {
        if(this.itemsTargets[key].querySelector('input[type="radio"]:checked')) {
          var value = parseFloat(this.itemsTargets[key].getAttribute("data-score"))
          voted_count += 1
          if(this.itemsTargets[key].querySelector('input[type="radio"]:checked').value == -1) {
            negative_score += value
            this.itemsTargets[key].classList.add("thumbs-down")
            this.itemsTargets[key].classList.remove("thumbs-up")
          } else {
            score += value
            this.itemsTargets[key].classList.add("thumbs-up")
            this.itemsTargets[key].getElementsByTagName("textarea")[0].value = ''
            this.itemsTargets[key].classList.remove("thumbs-down")
          }
        }
        
        
        if (this.hasPointsTarget)
          this.pointsTarget.innerHTML = score
        }
        for (var key in this.pointDeductionsTargets) {
          this.pointDeductionsTargets[key].innerHTML = negative_score * -1
        }

        for (var key in this.hasPointsTarget) {
          this.pointsTargets[key].innerHTML = score
        }
        if (this.hasVotedCountTarget) {
          this.votedCountTarget.innerHTML = voted_count
        }
    }


}