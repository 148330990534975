import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["expandable", "button"];

  connect() {
    console.log("connect");
    console.log(this.expandableTarget);
  }

  expand(e) {
    this.expandableTarget.classList.remove("collapsed");
    this.buttonTarget.remove();
  }
}
