import { Controller } from "stimulus";
import flatpickr from "flatpickr";
//require("flatpickr/dist/flatpickr.css")
const german = require("flatpickr/dist/l10n/de.js").default.de;

export default class extends Controller {
  static targets = [];
  datepicker;

  connect() {
    this.datepicker = flatpickr(this.element, {
      locale: german,
      // dateFormat: "d.m.Y",
      onChange: this.fireChangeEvent.bind(this),
    });
  }

  jumpToDate(e) {
    if (e.target != this.element && this.datepicker.selectedDates.length == 0) {
      this.datepicker.jumpToDate(e.detail, false);
    }
  }

  fireChangeEvent(selectedDates, dateStr, instance) {
    const event = new CustomEvent("datepicker:date:changed", {
      detail: selectedDates[0],
      bubbles: true,
    });
    this.element.dispatchEvent(event);
  }

  disconnect() {
    this.datepicker.destroy();
    this.datepicker = null;
  }
}
