// app/javascript/controllers/auto_save_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "form" ]
    static values = {
      id: String
    }

    connect() {
        // Create a unique key to store the form data into localStorage.
        // This could be anything as long as it's unique.
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
        // this.localStorageKey    =  window.location
        this.localStorageKey    =  this.idValue
        
        // Retrieve data from localStorage when the Controller loads.
        this.setFormData();
        var that = this
        $(document).on("ajax:success", function(req, res){
          if (res.finished == true) {
            Turbolinks.visit("/admin/besuchsberichte?state=finished")
          } else {
            if (res.top == true) {
              window.scrollTo(0, 0);
            }
            $(document.getElementById("visit_report_wrapper")).replaceWith(res.data)
          }
         
        });
    }

    updateContent(e, res) { //jquery ujs
        
      }

    clearLocalStorage() {
        // See if there is data stored for this particular form.
        if(localStorage.getItem(this.localStorageKey) != null) {
            // Clear data from localStorage when the form is submitted.
            localStorage.removeItem(this.localStorageKey);
        }
        // console.log("storage cleared")
        // document.dispatchEvent(new CustomEvent("localStorageCleared"));
        // console.log("storage cleared event sent")
        
    }


    abort() {
        this.clearLocalStorage()
        Turbolinks.visit(window.location)
    }

    save(e) {
      e.preventDefault();
      // Rails.fire(this.formTarget, 'submit')


      var form = $(this.formTarget);
      var url = form.attr('action');
      var that = this
      console.log(form)
      $.ajax({
      
        url: url + ".json",
        dataType: "JSON",
        method: "PATCH",
        data: form.serialize(), // serializes the form's elements.
        success : function(data) {              
          // alert('Data: '+data);
          console.log(data.data)
          $(document.getElementById("visit_report_wrapper")).replaceWith(data.data) ;
          that.clearLocalStorage()
          that.setFormData()
        },
        error : function(data) {              
          // console.log(data);
          $(document.getElementById("visit_report_wrapper")).replaceWith(data.data) ;
        },
      });
    }
    
    getFormData() {
        // Construct a set of of key/value pairs representing form fields and their values.
        // https://developer.mozilla.org/en-US/docs/Web/API/FormData
        const form = new FormData(this.formTarget);
        let data = []

        // Loop through each key/value pair.
        // https://developer.mozilla.org/en-US/docs/Web/API/FormData/entries#example
        for(var pair of form.entries()) {

            // We don't want to save the authenticity_token to localStorage since that is generated by Rails.
            // https://guides.rubyonrails.org/security.html#cross-site-request-forgery-csrf
            if (pair[0] != "authenticity_token") {
                data.push([pair[0], pair[1]])
            }

        }

        // Return the key/value pairs as an Object. Each key is a field name, and each value is the field value.
        // https://developer.mozilla.org/en-us/docs/Web/JavaScript/Reference/Global_Objects/Object/fromEntries
        return Object.fromEntries(data)
    }

    saveToLocalStorage() {
        const data = this.getFormData();
        // Save the form data into localStorage. We need to convert the data Object into a String.
        localStorage.setItem(this.localStorageKey, JSON.stringify(data));
        this.formTarget.classList.add("unsaved_changes")
    }

    setFormData() {
        // See if there is data stored for this particular form.
        if(localStorage.getItem(this.localStorageKey) != null) {
            // We need to convert the String of data back into an Object.
            const data = JSON.parse(localStorage.getItem(this.localStorageKey));
            // This allows us to have access to this.formTarget in the loop below.
            const form = this.formTarget;
            // Loop through each key/value pair and set the value on the corresponding form field.  
            Object.entries(data).forEach((entry)=>{
                let name    = entry[0];
                let value   = entry[1];
                let input   = form.querySelector(`[name='${name}']`);
                let is_radio = input.getAttribute("type") == 'radio'
                // todo geht das besser?
                if (is_radio) {
                  let radios = document.getElementsByName(name);
                  for(var x=0;x<radios.length;x++)                    // console.log(radios[x].value)
                    if(radios[x].value == value)
                      radios[x].checked = true
                } else {
                    input && (input.value = value);
                }
                
                    
            })
          this.formTarget.classList.add("unsaved_changes")
        }

    }
}