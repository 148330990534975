import { Controller } from "stimulus";
import flatpickr from "flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
const german = require("flatpickr/dist/l10n/de.js").default.de;

export default class extends Controller {
  static targets = [];
  static values = { currentDate: String }
  datepicker;

  connect() {
    const defaultDate = this.hasCurrentDateValue ? new Date(this.currentDateValue) : null;
    this.datepicker = flatpickr(this.element, {
      locale: german,
      defaultDate: defaultDate,
      plugins: [
        new monthSelectPlugin({
          shorthand: true, //defaults to false
          dateFormat: "F Y", //defaults to "F Y"
          altFormat: "F Y", //defaults to "F Y"
          theme: "light" // defaults to "light"
        })
      ],
      onChange: this.fireChangeEvent.bind(this),
    });
  }

  fireChangeEvent(selectedDates, dateStr, instance) {
    const event = new CustomEvent("monthpicker:date:changed", {
      detail: selectedDates[0],
      bubbles: true,
    });
    this.element.dispatchEvent(event);
  }

  disconnect() {
    this.datepicker.destroy();
    this.datepicker = null;
  }
}
