import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    $(this.element).on("ajax:success", this.updateContent.bind(this));
    $(this.element).on("ajax:error", this.onError.bind(this));
  }

  updateContent(e, res) { //jquery ujs
    const htmlString = typeof res == "string" ? JSON.parse(res).data : res.data;
    this.element.innerHTML = htmlString;
  }

  onError(e, res) { //jquery ujs
    console.error(res);
  }

  disconnect() {
    $(this.requestsTarget).off("ajax:success", "**");
    $(this.requestsTarget).off("ajax:error", "**");
  }
}
