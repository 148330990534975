import { Controller } from "stimulus"
const Hammer = require("hammerjs")

export default class extends Controller {
  static targets = ["content"]
  static values = { url: String, collapsed: Boolean, id: String }
  hammer;

  connect() {
    this.hammer = new Hammer(this.contentTarget);
    this.hammer.on("swipe", this.sendSwipeEvent.bind(this) );
  }

  updateTimeline(dateString, transition="", callback=null) {
    let url = new URL(this.urlValue);
    url.searchParams.set("starts_at", dateString);
    url.searchParams.set("transition", transition);

    fetch( url.toString() ).then((res) => res.json()).then((res) => {
      const html = res.locations[this.idValue];
      this.updateContent( html );
      if(callback) return callback();
    });
  }

  toggle() {
    // open or collapse depending on data attribute
    this.collapsedValue == true ? this.open() : this.collapse();
  }

  open(callback=null) {
    this.collapsedValue = false;
    const date = this.getController(document.getElementById("vacation_requests"), "vacation-requests").currentDateValue;
    this.updateTimeline(date, "open", () => {
      if(callback) return callback();
    });
  }

  collapse() {
    this.collapsedValue = true;
    let container = this.contentTarget.children[0]
    container?.classList.add("transition--collapse");
    container?.addEventListener("animationend", () => {
      this.contentTarget.innerHTML = "";
    });

  }

  updateContent(htmlString) {
    this.contentTarget.innerHTML = htmlString;
  }

  locationId() {
    return this.idValue;
  }

  sendSwipeEvent(e) {
    let swipeEvent = null;
    if(e.direction == Hammer.DIRECTION_RIGHT)
      swipeEvent = new Event("swipeRight");
    else if(e.direction == Hammer.DIRECTION_LEFT)
      swipeEvent = new Event("swipeLeft");
    if(swipeEvent)
      this.contentTarget.dispatchEvent(swipeEvent);
  }

  disconnect() {
    this.hammer.destroy();
    this.hammer = null;
  }

  getController(element, identifier) {
    return this.application.getControllerForElementAndIdentifier(element, identifier);
  }
}
