import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["locationTimeline", "requests"]
  static values = { url: String, currentDate: String, nextDate: String, prevDate: String }

  connect() {
    $(this.requestsTarget).on("ajax:success", this.updateRequestsAndTimelines.bind(this));
  }

  highlightRequest(e) {
    e.preventDefault();
    const id = e.target.dataset.requestId;
    const targetElement = this.requestsTarget.querySelector(`[data-request-id="${id}"]`)
    this.highlightAndScrollTo(targetElement);
  }

  highlightUserTimeline( e ) {
    e.preventDefault();
    const vacationRequest = e.target.closest(".vacation-request")?.dataset;
    if(!vacationRequest) return null;

    const id = vacationRequest.requestId;
    let date = new Date(vacationRequest.requestDate);
    date.setDate(date.getDate() - 13) // offset
    const currentDate = new Date(this.currentDateValue);
    const transition = date.getTime() != currentDate.getTime() ? (date > currentDate ? "jumpForwards" : "jumpBackwards") : ""
    const locationTimeline = this.getLocationTimelineController(vacationRequest.locationId);

    this.updateTimelines(date, transition, () => { // scroll all timelines to desired date
      if(locationTimeline.collapsedValue == true ) {
        locationTimeline.open(() => {
          const targetElement = locationTimeline.element.querySelector(`.day[data-request-id="${id}"]`);
          this.highlightAndScrollTo(targetElement);
        });
      } else {
        const targetElement = locationTimeline.element.querySelector(`.day[data-request-id="${id}"]`);
        this.highlightAndScrollTo(targetElement);
      }
    });
  }

  highlightAndScrollTo(element) {
    if(!element) return;
    const scrollOffset = element.getBoundingClientRect().top - (window.innerHeight/2);
    element.focus({preventScroll: true});
    window.scrollBy({ top: scrollOffset, behavior: 'smooth' });
  }

  slideForwards(e) {
    e.preventDefault();
    this.updateTimelines(this.nextDateValue, "next");
  }

  slideBackwards(e) {
    e.preventDefault();
    this.updateTimelines(this.prevDateValue, "prev");
  }

  // triggered from month select field
  jumpTimelines(e) {
    let date = new Date( e.detail );
    const currentDate = new Date(this.currentDateValue);
    const transition = date.getTime() != currentDate.getTime() ? (date > currentDate ? "jumpForwards" : "jumpBackwards") : ""

    this.updateTimelines( date, transition)
  }

  updateTimelines(dateString, transition="", callback=null) {
    const url = new URL(this.urlValue);
    url.searchParams.set("starts_at", dateString);
    url.searchParams.set("transition", transition);

    for(let locationTimeline of this.locationTimelineTargets) { // add all open location ids
      const ctrl = this.getController(locationTimeline, "location-timeline")
      if( !ctrl.collapsedValue )
        url.searchParams.append("ids[]", ctrl.idValue)
    }

    fetch( url.toString() ).then((res) => res.json()).then((res) => {
      this.currentDateValue = res.current_date;
      this.nextDateValue = res.next_date
      this.prevDateValue = res.prev_date

      for(const [locationId, html] of Object.entries(res.locations)) {
        this.getLocationTimelineController(locationId).updateContent(html);
      }
      if(callback) return callback();
    });
  }

  updateRequestsAndTimelines(e, res) {
    const requestListHtml = typeof res == "string" ? JSON.parse(res).data : res.data;
    this.requestsTarget.innerHTML = requestListHtml;
    this.updateTimelines(this.currentDateValue);
  }

  getController(element, identifier) {
    return this.application.getControllerForElementAndIdentifier(element, identifier);
  }

  getLocationTimelineController(locationId) {
    const locationTimeline = this.locationTimelineTargets.find( (element) => {
      return this.getController(element, "location-timeline").idValue == locationId
    });
    return this.getController(locationTimeline, "location-timeline");
  }

  disconnect() {
    $(this.requestsTarget).off("ajax:success", "**");
  }
}
